<script setup>
import { useRoute  } from 'vue-router';
const props = defineProps({
  menu: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['close']);

const route = useRoute();

function isActive (item) {
  if (item.id) {
    return route.meta.parent === item.id;
  }
  
  return route.path === item.linkTo;
}

function isSubmenuActive (submenu) {
  if (submenu.id) {
    return route.meta.parent === submenu.id;
  }

  return route.path === submenu.linkTo;
};

function menuClicked (item) {
  if (typeof item?.handler === 'function') {
    item.handler();
  }
  emit('close');
}
</script>

<template>
  <ul class="menu gap-2 p-0">
    <template v-for="(item, index) in menu" :key="index">

      <hr v-if="item.type === 'divider'">

      <li v-if="item.type === 'solo'">
        <nuxt-link @click="menuClicked" :to="item.linkTo" class="hover:bg-[#EDEFF1]" :class="{ 'active': isActive(item) }">
          <i class="text-[20px] text-neutral-400" :class="item.icon"></i>
          <span class="text-neutral-500">{{ item.name }}</span>
        </nuxt-link>
      </li>

      <li v-if="item.type === 'group'">
        <details open>
          <summary class="text-neutral-500">{{ item.group }}</summary>
          <ul class="menu">
            <template v-for="(submenu, submenuIndex) in item.menu" :key="submenuIndex">
              <li>
                <nuxt-link @click="menuClicked(submenu)" :to="submenu.linkTo" class="hover:bg-[#EDEFF1]" :class="{ 'active': isSubmenuActive(submenu) }">
                  <i class="text-[20px] text-neutral-400" :class="submenu.icon"></i>
                  <span class="text-neutral-500">{{ submenu.name }}</span>
                  <i v-if="submenu.iconAppend" class="text-[20px] text-neutral-400" :class="submenu.iconAppend"></i>
                </nuxt-link>
              </li>
            </template>
          </ul>
        </details>
      </li>
    </template>
  </ul>
</template>

<style scoped>
.active {
  background-color: #EDEFF1 !important;
}
</style>
